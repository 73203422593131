import React, { Component } from 'react'
import { Layout } from 'components/global'
import { navigate, Link, StaticQuery, graphql } from 'gatsby'
import SEO from 'utils/Seo'

import { StaticImage } from 'gatsby-plugin-image'
import { StyledButton } from 'components/shared'

const Query = props => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "Logo_lrg.png" }) {
          childImageSharp {
            gatsbyImageData(width: 800, quality: 100)
          }
        }
      }
    `}
    render={data => <Interstitial props={props} data={data} />}
  />
)

class Interstitial extends Component {
  constructor(props) {
    super(props)

    this.state = {
      href: '',
    }

    this.cancelRedirect = this.cancelRedirect.bind(this)
  }

  componentWillUnmount() {
    document.body.classList.remove('hide-disney-header')
  }

  componentDidMount() {
    document.body.classList.add('hide-disney-header')

    if (
      this.props.props.location.state === null ||
      typeof this.props.props.location.state === undefined
    ) {
      navigate('/')
      return true
    }
    const { href } = this.props.props.location.state

    this.setState({
      href,
    })
  }

  cancelRedirect() {
    clearTimeout(this.redirect)
  }

  render() {
    const { href } = this.state

    if (href !== '') {
      return (
        <Layout pageName="interstitial" interstitial>
          <SEO slug="interstitial" title="An Important Message" />
          <div className="w-full h-full bg-purple">
            <h1 className="sr-only">Aladdin The Musical</h1>
            <div className="w-[90%] mx-auto h-[80vh] min-h-[500px] flex items-center justify-center text-center">
              <div className="scroll-helper space-y-5 text-white max-w-[850px] mx-auto">
                <StaticImage
                  src="../images/logos/logo.png"
                  alt="Aladdin The Musical"
                  placeholder="NONE"
                  className="w-[70%] max-w-[650px] md:w-[400px] xl:w-[550px]"
                />
                <h2>PLEASE NOTE</h2>
                <p className="w-[90%] max-w-[750px] mx-auto">
                  Disney privacy practices and controls do not apply to the site
                  you are about to visit, as it is not controlled by Disney.
                  Please click below to say that you are happy to continue.
                </p>

                <div className="flex flex-col items-center justify-center sm:flex-row gap-4 w-[90%] mx-auto max-w-[450px]">
                  <StyledButton
                    onClick={() => navigate(-1)}
                    text="Go Back"
                    className=" text-black btn--interstitial-back buttonHover"
                  />
                  <StyledButton
                    external
                    href={href}
                    text="Continue"
                    className=" text-black btn--interstitial-back buttonHover"
                  />
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )
    }
    return <></>
  }
}

export default Query
